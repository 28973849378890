import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-jps-steelsolutions-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Termos e Serviços" description="JPS Steel Solutions é uma Serralharia especializada em construções de aço inox." image={'https://www.jps-steelsolutions.pt' + ogImage} siteUrl={'https://www.jps-steelsolutions.pt' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p><strong parentName="p"><em parentName="strong">{`Estes termos de utilização, juntamente com os documentos nele mencionados, estabelecem os termos em que você pode fazer uso do nosso site www.jps-steelsolutions`}{`.`}{`pt`}</em></strong></p>
      <p>{`Por favor, leia estes termos de utilização cuidadosamente antes de começar a usar o nosso site, pois eles se aplicam à sua utilização. Ao usar nosso site, você confirma que aceita estes termos de utilização e concorda em cumpri-los. Se você não concorda com estes termos de uso, você não deve usar nosso site.`}</p>
      <h3>{`Informações sobre nós`}</h3>
      <p>{`Esta plataforma é um site operado pela Appuro Inc., utilizado pela JPS`}</p>
      <h3>{`Mudanças nestes termos`}</h3>
      <p>{`Podemos alterar estes termos de uso a qualquer momento, alterando esta página. Por favor, verifique esta página de vez em quando para tomar conhecimento de quaisquer alterações que fizemos, pois são vinculativas para você.`}</p>
      <h3>{`Mudanças em nosso site`}</h3>
      <p>{`Podemos atualizar nosso site de tempos em tempos e alterar o conteúdo a qualquer momento. No entanto, observe que qualquer parte do conteúdo do nosso site pode estar desatualizado a qualquer momento e não temos a obrigação de atualizá-lo. Não garantimos que o nosso site, ou qualquer conteúdo dele, esteja livre de erros ou omissões.`}</p>
      <h3>{`Acedendo ao nosso site`}</h3>
      <p>{`Não garantimos que o nosso site, ou qualquer conteúdo dele, esteja sempre disponível ou seja ininterrupto. Podemos suspender, retirar, descontinuar ou alterar todo ou qualquer parte do nosso site sem aviso prévio. Não poderemos ser responsabilizados se, por qualquer motivo, o nosso site estiver indisponível a qualquer momento ou por qualquer período.`}</p>
      <p>{`Você também é responsável por garantir que todas as pessoas que acedam ao nosso site por meio de sua conexão com a Internet estejam cientes destes termos de uso e outros termos e condições aplicáveis, e que os cumpram. Ao fazer login no nosso site, você também está dando sua permissão explícita para nos correspondermos com seu e-mail. Você é livre para cancelar a assinatura de todo e qualquer e-mail a qualquer momento.`}</p>
      <h3>{`Direito de propriedade intelectual`}</h3>
      <p>{`Somos os proprietários ou licenciados de todos os direitos de propriedade intelectual do nosso site e do material nele publicado. Essas obras são protegidas por leis e acordos de direitos de autor em todo o mundo. Todos esses direitos são reservados. Quaisquer colaboradores identificados como os autores do conteúdo em nosso site devem ser sempre reconhecidos.`}</p>
      <p>{`Você não deve usar nenhuma parte do conteúdo do nosso site para fins comerciais sem obter uma licença para fazê-lo.`}</p>
      <h3>{`Sem confiança nas informações`}</h3>
      <p>{`O conteúdo do nosso site é fornecido apenas para informação geral. Não pretende ser um conselho no qual você deva confiar. Não fazemos representações ou garantias, expressas ou implícitas, de que o conteúdo em nosso site é preciso, completo ou atualizado.`}</p>
      <h3>{`Limites de responsabilidade`}</h3>
      <p>{`Nada nestes termos de uso exclui ou limita nossa responsabilidade por morte ou danos pessoais decorrentes de nossa negligência, ou nossa fraude ou deturpação fraudulenta, ou qualquer outra responsabilidade que não possa ser excluída ou limitada pela lei inglesa.`}</p>
      <p>{`Na medida do permitido por lei, excluímos todas as condições, garantias, representações ou outros termos que se possam aplicar ao nosso site ou a qualquer conteúdo nele, expresso ou implícito.`}</p>
      <p>{`Não seremos responsáveis por qualquer utilizador por qualquer perda ou dano, seja em contrato, ato ilícito, incluindo negligência, violação de dever estatutário ou de outra forma, mesmo se previsível, decorrente de ou em conexão com o uso de, ou incapacidade de usar, nosso site ou uso ou confiança em qualquer conteúdo exibido em nosso site. Não seremos responsáveis por lucros cessantes, vendas, negócios ou receitas, interrupção de negócios, perda de economias previstas, perda de oportunidade de negócios, boa vontade ou reputação, ou qualquer perda ou dano indireto ou consequencial.`}</p>
      <p>{`Não seremos responsáveis por qualquer perda ou dano causado por um vírus, ataque distribuído de negação de serviço ou outro material tecnologicamente prejudicial que possa infetar seu computador, telemóvel, programas de computador, dados ou outro material proprietário devido ao uso de nosso site ou ao fazer download de qualquer conteúdo nele, ou em qualquer site vinculado a ele. Não assumimos qualquer responsabilidade pelo conteúdo dos sites vinculados ao nosso site. Não seremos responsáveis por quaisquer perdas ou danos que possam surgir do uso deles.`}</p>
      <h3>{`Vírus`}</h3>
      <p>{`Não garantimos que o nosso site seja seguro ou livre de bugs ou vírus.`}</p>
      <p>{`Você é responsável por configurar sua tecnologia da informação, programas de computador e plataforma para aceder ao nosso site. Você deve usar seu próprio software de proteção contra vírus.`}</p>
      <p>{`Você não deve fazer mau uso do nosso site, introduzindo intencionalmente vírus, cavalos de troia, worms, bombas lógicas ou outro material malicioso ou tecnologicamente prejudicial. Você não deve tentar obter acesso não autorizado ao nosso site, ao servidor no qual o nosso site está armazenado ou a qualquer servidor, computador ou banco de dados conectado ao nosso site. Você não deve atacar o nosso site por meio de um ataque de negação de serviço ou de um ataque distribuído de negação de serviço.`}</p>
      <p>{`Ao violar esta disposição, você cometeria uma ofensa criminal nos termos da Lei de Uso Indevido de Computadores de 1990. Informaremos qualquer violação às autoridades responsáveis pela aplicação da lei e cooperaremos com essas autoridades, revelando a sua identidade a elas. No caso de tal violação, o seu direito de usar o nosso site cessará imediatamente.`}</p>
      <h3>{`Links e recursos de terceiros no nosso site`}</h3>
      <p>{`Quando o nosso site contém links para outros sites e recursos fornecidos por terceiros, esses links são fornecidos apenas para sua informação. Não temos controle sobre o conteúdo desses sites ou recursos.`}</p>
      <h3>{`Lei aplicável`}</h3>
      <p>{`Estes termos de uso, o seu assunto e sua formação (e quaisquer disputas ou reivindicações não contratuais) são regidos pela lei Portuguêsa. Ambos concordamos com a jurisdição exclusiva dos tribunais da Portugal.`}</p>
      <p>{`Esta política de uso aceitável estabelece os termos entre você e nós sob os quais você pode aceder ao nosso site. Esta política de uso aplica-se a todos os utilizadores e visitantes do nosso site. O uso do nosso site significa que você aceita e concorda em cumprir todas as políticas desta política de utilização, que complementam os nossos termos de utilização do site.`}</p>
      <h3>{`Usos Proibidos`}</h3>
      <h4>{`Você pode usar o nosso site apenas para fins legais. Você não pode usar nosso site:`}</h4>
      <ul>
        <li parentName="ul">{`De forma que viole qualquer lei ou regulamentação local, nacional ou internacional aplicável`}</li>
        <li parentName="ul">{`De forma que seja ilegal ou fraudulenta, ou tenha qualquer propósito ou efeito ilegal ou fraudulento`}</li>
        <li parentName="ul">{`Para enviar, receber, fazer upload, descarregar, usar ou reutilizar conscientemente qualquer material que não esteja em conformidade com nossos padrões de conteúdo`}</li>
        <li parentName="ul">{`Para transmitir qualquer publicidade ou material promocional não solicitado ou não autorizado ou solicitação semelhante`}</li>
        <li parentName="ul">{`Para transmitir intencionalmente quaisquer dados, envie ou carregue qualquer material que contenha vírus, cavalos de Tróia, worms, bombas-relógio, registradores de pressionamento de tecla, spyware, adware ou qualquer outro programa prejudicial ou código de computador semelhante projetado para afetar adversamente o funcionamento de qualquer software ou hardware de computador`}</li>
        <li parentName="ul">{`Você concorda em não reproduzir, duplicar, copiar ou revender qualquer parte do nosso site em violação das disposições dos nossos termos de utilização do site`}</li>
      </ul>
      <h3>{`Mudanças na Política de Uso Aceitável`}</h3>
      <p>{`Podemos alterar esta política de utilização a qualquer momento, alterando esta página. Espera-se que você verifique esta página de tempos em tempos para tomar conhecimento de quaisquer alterações que fizermos, pois elas são legalmente vinculativas para você. Algumas das disposições contidas nesta política de utilização também podem ser substituídas por disposições ou avisos publicados em outro lugar em nosso site.`}</p>
      <h3>{`Responsabilidade Pessoal do Utilizador`}</h3>
      <p>{`Ao usar este site, você assume a responsabilidade pessoal pelos resultados de suas ações. Você concorda em assumir total responsabilidade por qualquer dano ou dano que sofra como resultado do uso, ou não, das informações disponíveis neste site ou dos recursos disponíveis para download neste site. Você concorda em usar o julgamento e conduzir a devida diligência antes de tomar qualquer ação ou implementar qualquer plano ou política sugerida ou recomendada neste site.`}</p>
      <h3>{`Sem endossos`}</h3>
      <p>{`De vez em quando, este site fará referência a outros produtos, serviços e/ou especialistas. Qualquer referência desse tipo não pretende ser um endosso ou declaração de que as informações fornecidas pela outra parte são precisas. O site fornece essas informações como referência para os utilizadores. É sua responsabilidade conduzir a sua própria investigação e fazer a sua própria determinação sobre qualquer produto, serviço e/ou especialista.`}</p>
      <h3>{`SOMENTE PARA FINS EDUCACIONAIS E INFORMATIVOS`}</h3>
      <p>{`As informações contidas neste site e todos os recursos disponíveis por meio dele são apenas para fins educacionais e informativos.`}</p>
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      